<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { NewsletterFormProps } from "./NewsletterForm.props"
import { useFocusInvalidInputs } from "~/composables/useFocusInvalidInputs"

const { email } = defineProps<NewsletterFormProps>()

const emit = defineEmits<{ (e: "onSubscription"): void }>()

const form = ref()
const checkboxValue = ref(false)
const emailValue = ref(email ?? "")
const isEmailValid = ref(false)

/**
 * GTM Generated Lead Events
 */
const { event, sendEvent } = useGAEvent(`track_generate_lead`, "custom_event")
/**
 * @descriptiopn ...
 * @param {boolean} value
 */
const handleSubscription = () => {
  /**
   * This is the event that is sent to GTM
   * @todo It must be inserted in the response with status 200.
   */
  event.value = {
    event: "custom_event",
    action: "generate_lead",
    form_id: "Newsletter"
  }
  sendEvent({ clearEcommerce: false })

  emit("onSubscription")
}

const { valid, focusInvalidInputs } = useFocusInvalidInputs(form, true)
</script>

<template>
  <div
    class="newsletter-form-wrapper bg-white md:rounded-br-lg md:rounded-tl-lg"
  >
    
<FormKitLazyProvider config-file="true">
<UtilsMarkdown
      :content="$t('utils.newsletter.instruction')"
      class="mouse-bold lg:beaver mb-4 text-green-main md:mb-6"
    />

    <FormKit type="form" ref="form" :actions="false">
      <TextfieldsInputText
        @on-update-validity="isEmailValid = $event"
        type="text"
        name="emailField2"
        v-model="emailValue"
        validation="required|email"
        :label="$t('textBlock.utility.insertYourEmail')"
        is-readonly
        disabled
      />

      <UtilsButton
        @click="valid ? handleSubscription() : focusInvalidInputs()"
        :text="$t('utils.newsletter.subscribe')"
        class="pig-medium mb-4 mt-4 w-full text-center"
        theme="contained-yellow"
      />
    </FormKit>
    <div class="flex flex-col md:gap-3">
      <UtilsMarkdown
        class="mouse markdown__link-underlined"
        :content="$t('services.newsletter.privacyText')"
      />
      <UtilsMarkdown
        v-if="unsubscribeText"
        :content="unsubscribeText"
        class="mouse"
      />
    </div>
</FormKitLazyProvider>

  </div>
</template>
